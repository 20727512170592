<script setup lang="ts">
import { onUnmounted } from 'vue'

type Props = {
    wishlistName: string,
    statusFromServer: string,
    isLoading: boolean
}

const props = defineProps<Props>()

const emit = defineEmits([
    'setCreateNewWishList',
    'setWishlistName',
    'closeModal',
    'submitNewWishList',
    'clearWishlistData'
])

onUnmounted(() => {
    emit('setCreateNewWishList', false) 
})
</script>

<template>
    <form class="wishlist-modal-create-item-modal" @submit.prevent="emit('submitNewWishList')">
        <div class="wishlist-modal-create-item-modal-body">
            <div class="form-group">
                <label for="wishlistName" class="form-label">Name</label>
                <input
                    id="wishlistName"
                    name="wishlistName"
                    type="text"
                    placeholder="Name"
                    class="form-control"
                    :class="{ 'is-invalid': statusFromServer === 'error' }"
                    required
                    :value="wishlistName"
                    @input="emit('setWishlistName', $event)"
                >
                <div v-if="statusFromServer === 'error'" class="invalid-feedback">
                    The name must not be greater than 50 characters.
                </div>
            </div>
        </div>
        
        <div class="wishlist-modal-create-item-modal-footer d-flex">
            <button
                type="button"
                class="btn btn-light ml-auto"
               
                @click="emit('closeModal')"
            >
                Cancel
            </button>
            <button
                type="submit"
                class="btn btn-success d-flex align-items-center ml-2"
            >
                <span v-if="isLoading" class="spinner spinner-border spinner-border-sm mr-2" />
                
                Save
            </button>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.wishlist-modal-create-item-modal {

    &-body {
        padding: 16px;
        gap: 16px;
    }

    &-footer {
        padding: 16px 32px 32px 32px;
    }
}
</style>