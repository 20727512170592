<script setup lang="ts">

import { vOnClickOutside } from '@vueuse/components'
import TwoFieldsLoginForm   from './TwoFieldsLoginForm.vue'
import { computed, onUnmounted } from 'vue'
import  WishlistModalContent  from '@/Components/wishlist/WishlistModalContent.vue'
import { Link } from '@inertiajs/vue3'
 
type Props = {
    enteredEmail: string
    enteredPassword: string
    rememberMeCheckbox: boolean
    errorStatusFromServer: string,
    isUserLogged: boolean,
    // isLoading?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits([
    'closeModal',
    'submitModalLoginForm', 
    'updateEnteredEmail', 
    'updateEnteredPassword', 
    'updateRememberMeCheckbox', 
    'clearAuthorizationFormData',
    'clearInputs',
    'addListingToList'
])

const isInvalidValidationClass = computed(() => {
    return props.errorStatusFromServer ? 'is-invalid' : ''
})

onUnmounted(() => {
    emit('clearAuthorizationFormData')
    emit('clearInputs')  
})
</script>

<template>
    <div v-on-click-outside="() => emit('closeModal')" class="two-fields-login-modal-content">
        <div v-if="!isUserLogged">
            <h5 class="two-fields-login-modal-content-title mb-0">
                Login
            </h5>

            <div v-if="errorStatusFromServer" class="p-3">
                <div class="alert alert-warning mb-0">
                    {{ errorStatusFromServer }}
                </div>
            </div>
        
            <TwoFieldsLoginForm 
                :entered-email="enteredEmail"
                :entered-password="enteredPassword"
                :remember-me-checkbox="rememberMeCheckbox"
                :is-invalid-validation-class="isInvalidValidationClass"
                @update-entered-email="emit('updateEnteredEmail', $event)" 
                @update-entered-password="emit('updateEnteredPassword', $event)"
                @update-remember-me-checkbox="emit('updateRememberMeCheckbox', $event)"
                @submit-modal-login-form="emit('submitModalLoginForm', $event)"
            />
            <hr class="my-4">
            <p class="text-center two-fields-login-modal-content-footer mb-0">
                <small>Don't have an account yet? <Link :href="route('signupShow')">Sign Up </Link></small>
            </p>

            <!-- <div class="two-fields-login-modal-content-footer d-flex align-items-center justify-content-end">
                    <button
                        type="button"
                        class="btn btn-light"
                        data-dismiss="modal"
                        @click="emit('closeModal')"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        class="btn btn-success d-flex align-items-center"
            
                        @click="emit('submitModalLoginForm')"
                    >
                        <span v-if="isLoading" class="spinner spinner-border spinner-border-sm mr-2" />
                        Save
                    </button>
                </div> -->
        </div>
        <div v-else>
            <WishlistModalContent @close-modal="emit('closeModal')" />
        </div>
    </div>
</template>

<style lang="scss" scoped>

.two-fields-login-modal-content {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: white;
    overflow: hidden;
    border-radius: 10px;

    &:deep(.two-fields-login-form-body) {
        padding-bottom: 0;
    }
    
    &-title {
        padding: 36px 32px 20px;
        border-bottom: 1px solid rgba(236, 239, 244, .5)
    }

    &-footer {
        padding: 0px 32px 32px 32px;
        gap: 8px;
    }
}
</style>