<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const emit = defineEmits([
    'setCreateNewWishList'
])
</script>

<template>
    <div class="card wishlistItem wishlistItemCreate cursor-pointer wishlist-modal-create-item" @click="emit('setCreateNewWishList', true)">
        <div class="row no-gutters">
            <div class="col-4 d-flex align-items-center justify-content-center bg-dark card-img">
                <FontAwesomeIcon class="text-white wishlist-modal-create-item-icon" :icon="faPlus" />
            </div>
            <div class="col-8">
                <div class="card-body">
                    <h5 class="card-title mb-0">
                        Create a new list
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.wishlist-modal-create-item {
    margin-bottom: 16px;

    &-icon {
        font-size: 20px;
    }
}
</style>