<script setup lang="ts">

import { onMounted, onUnmounted, ref } from 'vue'
import WishlistModalItem from './WishlistModalItem.vue'
import WishlistModalCreateItem from './WishlistModalCreateItem.vue'
import WishlistModalCreateItemModal from './WishlistModalCreateItemModal.vue'
import { 
    setStatusFromServer,
    userWishlistsEndpoint,
    wishlistsCreateEndpoint,
    statusFromServer,
    clearWishlistData,
    selectedLocationID,
    addItemToWishlistEndpoint,
    setUserWishListLocationsIDs,
    userWishListLocationsIDs,
    isLoadingRequestWishlist,
    userWishlists,
    setUserWishlists
} from '@/Composables/wishlistLogic'
import { setToastData, setToastShow, setToastShowAtAdding } from '@/Composables/toastLogic'
import { WishlistDeleteOrCreate } from '@/Interfaces/wishlist/wishlist'

const emit = defineEmits([
    'closeModal'
])

const createNewWishList = ref(false)

const wishlistName = ref('')

onMounted(() => {
    if(!userWishlists.value.length) {

        const request = {
            url: '/wishlists/userLists'
        }
   
        userWishlistsEndpoint( request, (data) => {
        
            if (Array.isArray(data.wishlists)) {

                setUserWishlists(data.wishlists)
            } else {
                console.error('Unexpected data format after response from userWishlistsEndpoint:', data.wishlists)
            } 
        }) 
    }
})


const setCreateNewWishList = (value: boolean) : void => {
    createNewWishList.value = value
}

const setWishlistName  = (event: Event): void => {
    const target = event.target as HTMLInputElement

    wishlistName.value = target.value
}

const submitNewWishList = (): void => {

    const request = {
        url: '/wishlists',
        name: wishlistName.value
    }

    wishlistsCreateEndpoint(request, (data) => {

        if(data.status) {
            setStatusFromServer(data.status)

            const request = {
                url: '/wishlists/userLists'
            }

            userWishlistsEndpoint( request, (data) => {
            
                if (Array.isArray(data.wishlists)) {
                    setUserWishlists(data.wishlists)

                    setCreateNewWishList(false)
                } else {
                    console.error('Unexpected data format after response from userWishlistsEndpoint:', data.wishlists)
                }
            }) 
        }
    })
}

const addListingToList = (wishlistID: number, listingID: number): void => {

    const request = {
        wishlistID,
        listingID
    }
   
    addItemToWishlistEndpoint( request, (data) => {
    
        if(data.wishlist) {
            if ('path' in data.wishlist) {
                setToastData(data.wishlist as WishlistDeleteOrCreate)

                setToastShowAtAdding(true)

                setToastShow(true)
            }
        }
        
        if(selectedLocationID.value) {
            const updatedLocations = [...userWishListLocationsIDs.value, selectedLocationID.value]

            setUserWishListLocationsIDs(updatedLocations)
        }  

        const request = {
            url: '/wishlists/userLists'
        }

        userWishlistsEndpoint( request, (data) => {
            
            if (Array.isArray(data.wishlists)) {
                setUserWishlists(data.wishlists)

                setCreateNewWishList(false)
            } else {
                console.error('Unexpected data format after response from userWishlistsEndpoint:', data.wishlists)
            }
        }) 
    }) 

    emit('closeModal')
}

onUnmounted(() => {
    clearWishlistData() 
})

</script>

<template>
    <div class="wishlist-modal-content">
        <h5 class="wishlist-modal-content-title mb-0">
            {{ createNewWishList ? 'Create a list' : 'Save to a list' }}
        </h5>

        <div v-if="!createNewWishList" class="wishlist-modal-content-body ">
            <WishlistModalCreateItem @set-create-new-wish-list="setCreateNewWishList" />
        
            <div v-if="isLoadingRequestWishlist" class="spinner-wrap justify-content-center text-center">
                <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div v-else class="d-flex flex-column wishlist-modal-content-wrap">
                <WishlistModalItem
                    v-for="(list) in userWishlists"
              
                    :key="list.id"
                    :name="list.name"
                    :listings-count="list.listingsCount"
                    :image="list.image"
                    :user-wish-list-i-d="list.id"
                    :selected-location-i-d="selectedLocationID"
                    @add-listing-to-list="addListingToList"
                />
            </div>
        </div>
        <div v-if="!createNewWishList" class="wishlist-modal-content-footer d-flex">
            <button
                type="button"
                class="btn btn-light ml-auto"
                data-dismiss="modal"
                @click="emit('closeModal')"
            >
                Cancel
            </button>
        </div>

        <WishlistModalCreateItemModal
            v-if="createNewWishList"
            :wishlist-name="wishlistName"
            :status-from-server="statusFromServer"
            :is-loading="isLoadingRequestWishlist"
            @set-create-new-wish-list="setCreateNewWishList"
            @set-wishlist-name="setWishlistName"
            @close-modal="emit('closeModal')"
            @submit-new-wish-list="submitNewWishList"
        />
    </div>
</template>

<style lang="scss" scoped>

.wishlist-modal-content {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: white;
    overflow: hidden;
    border-radius: 10px;

    
    &-title {
        padding: 36px 32px 20px;
        border-bottom: 1px solid rgba(236, 239, 244, .5)
    }

    &-body {
        padding: 16px;
        gap: 16px;
    }

    &-footer {
        padding: 16px 32px 32px 32px;
    }

    &-wrap {
       gap: 16px;
    }
}
</style>