<script setup lang="ts">
import { computed } from 'vue'

type Props = {
    name: string,
    listingsCount: number
    image: string
    userWishListID: number
    selectedLocationID: number | null
}

const props = defineProps<Props>()

const emit = defineEmits([
    'addListingToList'
])

const wishlistModalItemContent = computed(() => {
    if(props.listingsCount === 1) {
        return `${ props.listingsCount } stay`
    } else if( props.listingsCount > 1) {
        return `${ props.listingsCount } stays`
    } else {
        return 'Nothing saved yet'
    }
})

</script>

<template>
    <div class="card wishlistItem cursor-pointer wishlist-modal-item" @click="emit('addListingToList', userWishListID, selectedLocationID)">
        <div class="row no-gutters">
            <div class="col-4 d-flex">
                <img
                    v-if="image"
                    :src="image"
                    class="card-img wishlist-modal-item-img"
                    :alt="name"
                    :title="name"
                >
                <img
                    v-else
                    src="/images/noImage.jpg"
                    class="card-img wishlist-modal-item-img"
                    :alt="name"
                    :title="name"
                >
            </div>
            <div class="col-8">
                <div class="card-body wishlist-modal-item-body">
                    <h5 class="card-title">
                        {{ name }}
                    </h5>
                    <p class="card-text">
                        {{ wishlistModalItemContent }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.wishlist-modal-item {
    
    &-img {
        max-height: 155px;
        object-fit: cover;
    }

    &-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }
}
</style>